import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

const Footer = () => (
  <footer className="footer">
    <Container>
      <Row>
        <Col md="5">
          <h2>
            <Link to="/">Micro Income</Link>
          </h2>
        </Col>
        <Col xs={12} md>
          <ul className="list-unstyled">
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/#contact-us">Contact Us</Link>
            </li>
          </ul>
        </Col>
        <Col xs={12} md>
          <ul className="list-unstyled">
            <li>
              <Link to="/privacy-policy/#opt-out">
                Do Not Sell My Personal Information
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="disclosure-library">
          <ul className="list-unstyled">
            <li>
              <Link to="/">Microincome.com</Link>
            </li>
            <li>
              <Link to="/terms-of-service/">
                Terms &amp; Conditions Disclosure Library
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
      <hr />
      <p className="disclaimer">
        © {new Date().getFullYear()} Microincome.com | All rights reserved.
        {` `} None of your data will ever be used without your permission.
      </p>
      <p className="disclaimer">
        {` `} Microincome.com is owned by Ant Money Inc. which has a financial
        interest in ATM.com&trade; and Learn &amp; Earn&trade;
      </p>
    </Container>
  </footer>
)

/*

// unused

import FacebookSvg from "./Facebook.svg"
import InstagramSvg from "./Instagram.svg"
import TwitterSvg from "./Twitter.svg"
import LinkedInSvg from "./LinkedIn.svg"

<ul className="list-unstyled list-flex">
  <li>
    <Link to="#">
      <FacebookSvg title="Facebook" />
    </Link>
  </li>
  <li>
    <Link to="#">
      <InstagramSvg title="Instagram" />
    </Link>
  </li>
  <li>
    <Link to="#">
      <TwitterSvg title="Twitter" />
    </Link>
  </li>
  <li>
    <Link to="#">
      <LinkedInSvg title="LinkedIn" />
    </Link>
  </li>
</ul>

<Col>
  <ul className="list-unstyled">
    <li>
      <Link to="#">About</Link>
    </li>
    <li>
      <Link to="#">What is Micro Income?</Link>
    </li>
    <li>
      <Link to="#">FAQ</Link>
    </li>
  </ul>
</Col>
*/

export default Footer
