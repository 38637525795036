import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

import NavBar from "./navbar"
import Footer from "./footer"

// Load scss styles
import "../styles/main.scss"

const Layout = ({ children, pageInfo }) => (
  <Container fluid className="main-container">
    <NavBar pageInfo={pageInfo} />
    <main>{children}</main>
    <Footer />
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageInfo: PropTypes.object,
}

export default Layout
