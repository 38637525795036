import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

import LogoSvg from "./Logo.svg"
import MenuSvg from "./Menu.svg"
import CloseSvg from "./Close.svg"

const CustomNavBar = ({ pageInfo }) => {
  const handleLinkClick = (e, target) => {
    if (typeof window !== "undefined") {
      if (window.location.pathname === "/") {
        if (e) e.preventDefault()
        scrollTo(target)
      }
    }
  }

  // TODO: speed up display of offcanvas navbar menu, seems to have a delay on click

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      id="site-navbar"
      className={pageInfo.pageName && `page-${pageInfo.pageName}`}
    >
      <Container>
        <Link to="/">
          <Navbar.Brand as="span">
            <LogoSvg title="MicroIncome.com" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="navbarCollapse" data-toggle="offcanvas">
          <MenuSvg title="Open menu" />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarCollapse" className="offcanvas-collapse">
          <div className="menu">
            <Navbar.Toggle
              aria-controls="navbarCollapse"
              className="close"
              data-toggle="offcanvas"
            >
              <CloseSvg title="Close menu" />
            </Navbar.Toggle>
            <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/top-15-apps/">
                <Nav.Link as="span" eventKey="Top 15 Apps">
                  Top 15 Apps
                </Nav.Link>
              </Link>
              <Link
                to="/#newsletter"
                onClick={(e) => handleLinkClick(e, "#newsletter")}
              >
                <Nav.Link as="span" eventKey="Newsletter">
                  Newsletter
                </Nav.Link>
              </Link>
              <Link
                to="/#contact-us"
                onClick={(e) => handleLinkClick(e, "#contact-us")}
              >
                <Nav.Link as="span" eventKey="Contact Us">
                  Contact Us
                </Nav.Link>
              </Link>
            </Nav>
            <Navbar.Text>
              &copy; {new Date().getFullYear()} Microincome.com&trade; | All
              rights reserved. None of your data will ever be used without your
              permission.
            </Navbar.Text>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

CustomNavBar.propTypes = {
  pageInfo: PropTypes.object,
}

CustomNavBar.defaultProps = {
  pageInfo: {},
}

export default CustomNavBar
