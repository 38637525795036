import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Parser from "html-react-parser"
import { useForm } from "react-hook-form"
import {
  Container,
  Form,
  InputGroup,
  FormControl,
  Button,
  Spinner,
} from "react-bootstrap"

const Newsletter = () => {
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    errors,
    formState: { isSubmitting },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      // fetch api
      const response = await fetch(process.env.NEWSLETTER_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })

      // parse json body
      const body = await response.json()

      // error handling
      let error
      if (response.status >= 400) {
        error = new Error(body.error || "Form error")
        error.name = body.name || "submit"
        throw error
      }

      const { result, msg } = await addToMailchimp(data.email)
      if (result === "error") {
        error = new Error(msg)
        error.name = "email"
        throw error
      }

      // success!
      setSubmitted(true)
    } catch (error) {
      setError(error.name, { type: "manual", message: error.message })
    }
  }

  const showThankYou = (
    <div className="msg-confirm">
      <p>You're on the list!</p>
    </div>
  )

  const showForm = (
    <Form onSubmit={handleSubmit(onSubmit)} method="post">
      <InputGroup>
        <FormControl
          placeholder="Your Email"
          aria-label="Your Email"
          autoComplete="email"
          name="email"
          ref={register({
            required: "This field is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          disabled={isSubmitting}
          isInvalid={!!errors.email}
        />
        <InputGroup.Append>
          <Button type="submit">
            {isSubmitting ? (
              <Spinner animation="border" className="spinner" />
            ) : (
              "Join"
            )}
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {(!!errors.email || !!errors.submit) && (
        <div className="pt-3 pb-0 text-danger">
          {Parser(errors.email.message) || Parser(errors.submit.message)}
        </div>
      )}
    </Form>
  )

  return (
    <section id="newsletter" className="section-newsletter">
      <Container>
        <h3>Subscribe to our Newsletter.</h3>
        {submitted && Object.keys(errors).length === 0
          ? showThankYou
          : showForm}
      </Container>
    </section>
  )
}

export default Newsletter
